import React, { Component } from "react";
import Select from "react-select";
import { Helmet } from "react-helmet";
import Toggle from "react-toggle";
// import DesktopTimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "react-day-picker/lib/style.css";
import Tooltip from "@material-ui/core/Tooltip";
import "./dashboard.css";

import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import Geosuggest from "react-geosuggest";
import icon1 from "./icons/editImg.png";
import placeholder from "./images/barPlaceholderAlt.png";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Form } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import icon2 from "./icons/addyellow.png";
import icon3 from "./icons/subtractGray.png";
import icon4 from "./icons/addGray.png";
import info from "./icons/info.png";
import subtract from "./icons/subtractNoBG.png";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import DayPickerInput from "react-day-picker/DayPickerInput";
import dayjs from "dayjs";
import {
  DesktopTimePicker,
  StaticDesktopTimePicker,
} from "@mui/x-date-pickers";
import { LaptopWindows } from "@material-ui/icons";
import firebase from "firebase/compat/app";

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontFamily: "proxima-nova, sans-serif",
  },
}))(Tooltip);

const options = [
  { value: "Bar", label: "Bar	" },
  { value: "Pub", label: "Pub" },
  { value: "Café", label: "Café" },
  { value: "Bistro", label: "Bistro" },
  { value: "Restaurant", label: "Restaurant" },
  { value: "Lounge", label: "Lounge" },
  { value: "Bodega", label: "Bodega" },
  { value: "Wine bar", label: "Wine bar" },
  { value: "Gastro pub", label: "Gastro pub" },
  { value: "Cocktail bar", label: "Cocktail bar" },
  { value: "Latino bar", label: "Latino bar" },
  { value: "Karaoke", label: "Karaoke" },
  { value: "Jazz bar", label: "Jazz bar" },
  { value: "Rock bar", label: "Rock bar" },
  { value: "Piano bar", label: "Piano bar" },
  { value: "Bowling", label: "Bowling" },
  { value: "Beach bar", label: "Beach bar" },
  { value: "Food truck", label: "Food truck" },
  { value: "Discothèque", label: "Discothèque" },
  { value: "Champagne bar", label: "Champagne bar" },
  { value: "LGBTQ+", label: "LGBTQ+" },
  { value: "Gentlemens bar", label: "Gentlemens bar" },
  { value: "Ladies bar", label: "Ladies bar" },
  { value: "Libertine", label: "Libertine" },
  { value: "Pool tables", label: "Pool tables" },
  { value: "Game bar", label: "Game bar" },
  { value: "Distillery", label: "Distillery" },
  { value: "Brewerie", label: "Brewerie" },
  { value: "Comedy bar", label: "Comedy bar" },
  { value: "Cabaret", label: "Cabaret" },
  { value: "Hotel bar", label: "Hotel bar" },
  { value: "Casino", label: "Casino" },
  { value: "Rooftop bar", label: "Rooftop bar" },
  { value: "Sports bar", label: "Sports bar" },
  { value: "Coffee shop", label: "Coffee shop" },
  { value: "Vegetarian", label: "Vegetarian" },
];

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {},
      adress: "",
      category: [],
      coordinates: {
        latitude: 0,
        longitude: 0,
      },
      events: [],
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
      sunday: "",
      id: "",
      link: "",
      name: "",
      number: "",
      email: "",
      food: false,
      student: false,
      image: null,
      url: placeholder,
      covid: false,
      cropToggle: false,
      closePicker: false,
      closePicker1: false,
      src: null,
      crop: {
        unit: "%",
        width: 70,
        height: 70,
        aspect: 6 / 5,
      },
      croppedImageUrl: null,
      closedFrom: "",
      closedTo: "",
      multiDay: false,
      closedDays: [false, false, false, false, false, false, false],
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  handleValueChange() {
    const data = localStorage.getItem("info");
    const info = JSON.parse(data);
    if (info !== undefined) {
      firebase
        .storage()
        .ref("barImages")
        .child(info.id)
        .getDownloadURL()
        .then((url) => {
          this.setState({ url });
        })
        .catch((error) => {
          console.log(error.message);
        });
      let newCat = [];
      info.category.forEach((element) => {
        newCat = [...newCat, { value: element, label: element }];
      });
      this.setState({
        info: info,
        adress: info.adress,
        category: newCat,
        coordinates: info.coordinates,
        events: info.events,
        monday: info.hours.monday,
        tuesday: info.hours.tuesday,
        wednesday: info.hours.wednesday,
        thursday: info.hours.thursday,
        friday: info.hours.friday,
        saturday: info.hours.saturday,
        sunday: info.hours.sunday,
        id: info.id,
        link: info.link,
        name: info.name,
        number: info.number,
        email: info.email,
        facebook: info.facebook || "www.facebook.com",
        instagram: info.instagram || "www.instagram.com",
        tiktok: info.tiktok || "www.tiktok.com",
        food: info.food || false,
        student: info.student || false,
        covid: info.covid || false,
        closedFrom: info.closedFrom || "",
        closedTo: info.closedTo || "",
        multiDay: info.closedTo != "" ? true : false,
        closedDays: info.closedDays || [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ],
        faded: info.faded || false,
      });
    }
  }

  getLocation(suggest) {
    if (suggest != undefined) {
      this.setState({
        location: suggest.location,
        coordinates: {
          latitude: suggest.location.lat,
          longitude: suggest.location.lng,
        },
        adress: suggest.label,
      });
      this.handleChange();
    }
  }

  handleChange() {
    document.getElementById("saveButton").style.visibility = "visible";
    document.getElementById("saveButton").style.opacity = "1";
  }

  handleSaveData = () => {
    document.getElementById("saveButton").style.opacity = "0";
    setTimeout(function () {
      document.getElementById("saveButton").style.visibility = "hidden";
    }, 1000);

    let newCat = [];
    if (this.state.category) {
      this.state.category.forEach((element) => {
        newCat = [...newCat, element.value];
      });
    }
    //update firebase
    const ref = firebase.firestore().collection("bars").doc(this.state.id);
    ref.update({
      category: newCat,
      hours: {
        monday: this.state.monday,
        tuesday: this.state.tuesday,
        wednesday: this.state.wednesday,
        thursday: this.state.thursday,
        friday: this.state.friday,
        saturday: this.state.saturday,
        sunday: this.state.sunday,
      },
      link: this.state.link || "",
      number: this.state.number || "",
      email: this.state.email || "",
      facebook: this.state.facebook || "",
      instagram: this.state.instagram || "",
      tiktok: this.state.tiktok || "",
      food: this.state.food,
      student: this.state.student,
      covid: this.state.covid,
      adress: this.state.adress,
      coordinates: this.state.coordinates,
      closedFrom: this.state.closedFrom,
      closedTo: this.state.closedTo,
      closedDays: this.state.closedDays,
      faded: this.state.faded,
      name: this.state.name,
    });

    //update localStorage
    let info = this.state.info;
    info.category = newCat;
    info.hours = {
      monday: this.state.monday,
      tuesday: this.state.tuesday,
      wednesday: this.state.wednesday,
      thursday: this.state.thursday,
      friday: this.state.friday,
      saturday: this.state.saturday,
      sunday: this.state.sunday,
    };
    info.link = this.state.link || "";
    info.number = this.state.number || "";
    info.email = this.state.email || "";
    info.facebook = this.state.facebook || "";
    info.instagram = this.state.instagram || "";
    info.tiktok = this.state.tiktok || "";
    info.food = this.state.food;
    info.student = this.state.student;
    info.covid = this.state.covid;
    info.closedFrom = this.state.closedFrom;
    info.closedTo = this.state.closedTo;
    info.closedDays = this.state.closedDays;
    info.faded = this.state.faded;
    info.name = this.state.name;
    info.adress = this.state.adress;

    localStorage.setItem("info", JSON.stringify(info));


    console.log("this.state.id==>>" + JSON.stringify(this.state.id));
    //upload image to firebase
    if (this.state.image != null) {
      const uploadTask = firebase
        .storage()
        .ref(`barImages/${this.state.id}`)
        .put(this.state.image);
      uploadTask.on(
        "state_changed",
        () => {
          //progress
        },
        () => {
          alert("The image could not be uploaded, try again!");
        },
        () => {
          this.props.history.push("/overview");
        }
      );
    }
    this.setState({
      cropToggle: false,
      src: this.state.image,
    });
  };

  handleUpload = (e) => {
    let image = "";
    if (e.target.files[0]) {
      image = e.target.files[0];
      if (image.size > 15000000) {
        alert("Image is too large, try with another one.");
      } else if (image.size < 100000) {
        alert("Image is too small, try with another one.");
      } else {
        const url = URL.createObjectURL(image);
        this.setState({ image, url });
      }
    }
    if (image.size < 15000000) {
      this.handleChange();
    }
  };

  //handle crop

  handleFile = (e) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      this.setState({
        src: fileReader.result,
        cropToggle: true,
      });
    };
    fileReader.readAsDataURL(e.target.files[0]);
  };

  handleSubmit = (e) => {
    this.handleChange();
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const url = this.getCroppedImg(this.imageRef, crop);
      this.setState({ url });
    }
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, "cropped.jpg");
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    this.setState({
      image: croppedImage,
      url: URL.createObjectURL(croppedImage),
    });
  }

  handleClosed(index) {
    let closedDays = this.state.closedDays;
    closedDays[index] = !closedDays[index];
    this.setState({ closedDays });
    this.handleChange();
  }

  render() {
    const { t } = this.props;
    /*const options = [
            { value: t('overview.bar'), label: t('overview.bar') },
            { value: t('overview.pub'), label: t('overview.pub') },
            { value: t('overview.cafe'), label: t('overview.cafe') },
            { value: t('overview.bistro'), label: t('overview.bistro') },
            { value: t('overview.restaurant'), label: t('overview.restaurant') },
            { value: t('overview.lounge'), label: t('overview.lounge') },
            { value: t('overview.bodega'), label: t('overview.bodega') },
            { value: t('overview.wineBar'), label: t('overview.wineBar') },
            { value: t('overview.gastroPub'), label: t('overview.gastroPub') },
            { value: t('overview.cocktailBar'), label: t('overview.cocktailBar') },
            { value: t('overview.latinoBar'), label: t('overview.latinoBar') },
            { value: t('overview.karaoke'), label: t('overview.karaoke') },
            { value: t('overview.jazzBar'), label: t('overview.jazzBar') },
            { value: t('overview.rockBar'), label: t('overview.rockBar') },
            { value: t('overview.pianoBar'), label: t('overview.pianoBar') },
            { value: t('overview.bowling'), label: t('overview.bowling') },
            { value: t('overview.beachBar'), label: t('overview.beachBar') },
            { value: t('overview.disco'), label: t('overview.disco') },
            { value: t('overview.champagneBar'), label: t('overview.champagneBar') },
            { value: t('overview.gayBar'), label: t('overview.gayBar') },
            { value: t('overview.gentlemensBar'), label: t('overview.gentlemensBar') },
            { value: t('overview.ladiesBar'), label: t('overview.ladiesBar') },
            { value: t('overview.libertine'), label: t('overview.libertine') },
            { value: t('overview.poolTables'), label: t('overview.poolTables') },
            { value: t('overview.gameBar'), label: t('overview.gameBar') },
            { value: t('overview.distillery'), label: t('overview.distillery') },
            { value: t('overview.brewerie'), label: t('overview.brewerie') },
            { value: t('overview.comedyBar'), label: t('overview.comedyBar') },
            { value: t('overview.cabaret'), label: t('overview.cabaret') },
            { value: t('overview.hotelBar'), label: t('overview.hotelBar') },
            { value: t('overview.casino'), label: t('overview.casino') },
            { value: t('overview.rooftopBar'), label: t('overview.rooftopBar') },
        ]*/
    const { crop, profile_pic, src } = this.state;
    return (
      <div className="w-100">
        <Helmet>
          <style>
            {
              "body { background-color: #F7F7F7 !important;} .geosuggest { margin: 0 !important; width: 270px !important;} .geosuggest__input { width: 270px !important; padding: 10px 10px !important; font-size: 11pt !important; color: #323232 !important;} @media screen and (max-width: 1025px) { .geosuggest__input{width:87% !important; height: 20px !important}.css-yk16xz-control, .css-1hwfws3{height: 25px !important}}"
            }
          </style>
        </Helmet>
        <div className="saveDiv">
          <a
            id="saveButton"
            className="yellowBtn linkStyle saveButton boxShadow"
            onClick={() => {
              this.handleSaveData();
            }}
          >
            {t("overview.save")}
          </a>
        </div>
        <p className="tabTitle">{t("overview.overview")}</p>
        <div className="flexBox">
          <div className="content boxShadow">
            <div className="contactDiv">
              <p className="contentTitle topTitle">{t("overview.contact")}</p>
              <p className="contentSub">{t("overview.name")}</p>
              <form>
                <input
                  className="longInput contactInput"
                  type="text"
                  placeholder={this.state.name}
                  onChange={(event) => {
                    this.handleChange();
                    this.setState({ name: event.target.value });
                  }}
                ></input>
              </form>
              <p className="contentSub">{t("overview.teleNumber")}</p>
              <form>
                <input
                  className="longInput contactInput"
                  type="text"
                  value={this.state.number}
                  onChange={(event) => {
                    this.handleChange();
                    this.setState({ number: event.target.value });
                  }}
                ></input>
              </form>
              <p className="contentSub">{t("overview.emailAddress")}</p>
              <form>
                <input
                  className="longInput contactInput"
                  type="text"
                  placeholder={this.state.email}
                  onChange={(event) => {
                    this.handleChange();
                    this.setState({ email: event.target.value });
                  }}
                ></input>
              </form>
              <p
                className={`contentSub ${this.state.info.subscription > 0 || this.state.info.trial
                  ? ""
                  : "disabled"
                  }`}
              >
                {t("overview.website")}
              </p>
              <form>
                <input
                  className={`longInput contactInput ${this.state.info.subscription > 0 || this.state.info.trial
                    ? ""
                    : "disabled"
                    }`}
                  type="text"
                  placeholder={this.state.link}
                  onChange={(event) => {
                    this.handleChange();
                    this.setState({ link: event.target.value });
                  }}
                ></input>
              </form>
              <p
                className={`contentSub ${this.state.info.subscription > 0 || this.state.info.trial
                  ? ""
                  : "disabled"
                  }`}
              >
                {t("overview.social")}
              </p>

              <p className="noteTitle">{t("overview.copyandpasteUrl")}</p>

              <input
                className={`longInput contactInput ${this.state.info.subscription > 0 || this.state.info.trial
                  ? ""
                  : "disabled"
                  }`}
                type="text"
                value={this.state.facebook}
                onChange={(event) => {
                  this.handleChange();
                  this.setState({ facebook: event.target.value });
                }}
              ></input>
              <input
                className={`longInput contactInput ${this.state.info.subscription > 0 || this.state.info.trial
                  ? ""
                  : "disabled"
                  }`}
                type="text"
                value={this.state.instagram}
                onChange={(event) => {
                  this.handleChange();
                  this.setState({ instagram: event.target.value });
                }}
              ></input>
              <input
                className={`longInput contactInput ${this.state.info.subscription > 0 || this.state.info.trial
                  ? ""
                  : "disabled"
                  }`}
                type="text"
                value={this.state.tiktok}
                onChange={(event) => {
                  this.handleChange();
                  this.setState({ tiktok: event.target.value });
                }}
              ></input>
            </div>
            <div className="otherDiv">
              <p className={`contentSub ${this.state.info.subscription > 0 || this.state.info.trial
                ? ""
                : "disabled"
                }`}>{t("overview.category")}</p>
              <Select
                isMulti
                className="categorySelect"
                value={this.state.category}
                options={options}
                onChange={(selected) => {
                  this.handleChange();
                  this.setState({ category: selected });
                }}
              />
              <p className={`contentSub ${this.state.info.subscription > 0 || this.state.info.trial
                ? ""
                : "disabled"
                }`}>
                {t("overview.otherInfo")}
                <CustomTooltip
                  className="tooltipSub"
                  title="'Use full logo image' will display your entire image, without the faded feature, in the mobile application instead of displaying the name of your bar."
                  placement="top"
                  arrow
                >
                  <img
                    className="infoIcon infoIconDash"
                    src={info}
                    alt="info"
                  ></img>
                </CustomTooltip>
              </p>
              {/* <hr className="lineBreak"></hr> */}
              <label>
                <span className="toggleTag">Use full logo image:</span>
                <Toggle
                  className="toggleBtn"
                  icons={false}
                  checked={this.state.faded}
                  onChange={() => {
                    this.handleChange();
                    this.setState({ faded: !this.state.faded });
                  }}
                />
              </label>
              {/* <hr className="lineBreak"></hr> */}
              {/* <label>
                <span className="toggleTag">{t("overview.estServes")}</span>
                <Toggle
                  className="toggleBtn"
                  icons={false}
                  checked={this.state.food}
                  onChange={() => {
                    this.handleChange();
                    this.setState({ food: !this.state.food });
                  }}
                />
              </label> */}
              {/* <hr className="lineBreak"></hr> */}
              {/* <label>
                <span className="toggleTag">{t("overview.student")}</span>
                <Toggle
                  className="toggleBtn"
                  icons={false}
                  checked={this.state.student}
                  onChange={() => {
                    this.handleChange();
                    this.setState({ student: !this.state.student });
                  }}
                />
              </label> */}
              {/* <hr className="lineBreak"></hr> */}
            </div>
            <div className="positionDiv">
              <p className={`contentSub ${this.state.info.subscription > 0 || this.state.info.trial
                ? ""
                : "disabled"
                }`}>{t("overview.position")}</p>
              <p className="positionNote">{t("overview.positionAddress")}</p>
              <Geosuggest
                initialValue={this.state.adress}
                onSuggestSelect={(suggest) => this.getLocation(suggest)}
              />
            </div>
          </div>
          <div className="floatRight overview2ndDiv">
            <div
              className={
                this.state.cropToggle
                  ? "contentSideImageCrop contentImageBox"
                  : "contentSideImage contentImageBox"
              }
            >
              <Form onChange={this.handleSubmit}>
                <label htmlFor="profile_pic"></label>
                <input
                  className="imgFileInput"
                  name="file"
                  id="file"
                  type="file"
                  onChange={this.handleFile}
                ></input>
                {this.state.cropToggle && (
                  <div>
                    <ReactCrop
                      className="cropBox"
                      src={src}
                      crop={crop}
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  </div>
                )}
              </Form>
              <label for="file">
                {!this.state.cropToggle && (
                  <div>
                    <img
                      className="imageBox imageBoxOverview boxShadow"
                      src={this.state.url}
                    ></img>
                    <img
                      className="editIconOverview boxShadow"
                      src={icon1}
                    ></img>
                  </div>
                )}
              </label>
            </div>
            <div className="contentSideInner boxShadow">
              <div className="timeDiv">
                <div className="scheduleDiv">
                  <p className="contentTitle topTitle">
                    {t("overview.openingHours")}
                  </p>
                  <form className="timeSheet">
                    <div className="timeBox">
                      <label className="timeLabel">
                        {t("overview.monday")}
                      </label>

                      {!this.state.closedDays[0] && (
                        <div className="datpicker-content">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                minutesStep="30"
                                // defaultValue={this.state.monday.substring(0, 5)}
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.monday.substring(0, 5)
                                )}
                                skipDisabled={true}
                                views={["hours", "minutes"]}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    monday:
                                      value.format("HH:mm:ss").substring(0, 5) +
                                      this.state.monday.substring(5, 11),
                                  });
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <b>-</b>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                className="datePicker"
                                minutesStep="30"
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.monday.substring(6, 11)
                                )}
                                skipDisabled={true}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    monday:
                                      this.state.monday.substring(0, 6) +
                                      value.format("HH:mm:ss").substring(0, 5),
                                  });
                                }}
                                views={["hours", "minutes"]}
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          <img
                            className="removeIcon"
                            onClick={() => this.handleClosed(0)}
                            src={icon3}
                            alt="subtract"
                          ></img>
                        </div>
                      )}

                      {this.state.closedDays[0] && (
                        <div className="datpicker-content justify-content-between">
                          <label className="closedLabel">Closed</label>
                          <img
                            className="addIcon"
                            onClick={() => this.handleClosed(0)}
                            src={icon4}
                            alt="add"
                          ></img>
                        </div>
                      )}
                      {/* </div> */}
                    </div>

                    <div className="timeBox">
                      <label className="timeLabel">
                        {t("overview.tuesday")}
                      </label>
                      {!this.state.closedDays[1] && (
                        <div className="datpicker-content">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                minutesStep="30"
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.tuesday.substring(0, 5)
                                )}
                                skipDisabled={true}
                                views={["hours", "minutes"]}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    tuesday:
                                      value.format("HH:mm:ss").substring(0, 5) +
                                      this.state.tuesday.substring(5, 11),
                                  });
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <b>-</b>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                className="datePicker"
                                minutesStep="30"
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.tuesday.substring(6, 11)
                                )}
                                skipDisabled={true}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    tuesday:
                                      this.state.tuesday.substring(0, 6) +
                                      value.format("HH:mm:ss").substring(0, 5),
                                  });
                                }}
                                views={["hours", "minutes"]}
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          <img
                            className="removeIcon"
                            onClick={() => this.handleClosed(1)}
                            src={icon3}
                            alt="subtract"
                          ></img>
                        </div>
                      )}
                      {this.state.closedDays[1] && (
                        <div className="datpicker-content justify-content-between">
                          <label className="closedLabel">Closed</label>
                          <img
                            className="addIcon"
                            onClick={() => this.handleClosed(1)}
                            src={icon4}
                            alt="add"
                          ></img>
                        </div>
                      )}
                    </div>
                    <div className="timeBox">
                      <label className="timeLabel">
                        {t("overview.wednesday")}
                      </label>
                      {!this.state.closedDays[2] && (
                        <div className="datpicker-content">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                minutesStep="30"
                                // defaultValue={this.state.monday.substring(0, 5)}
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.wednesday.substring(0, 5)
                                )}
                                skipDisabled={true}
                                views={["hours", "minutes"]}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    wednesday:
                                      value.format("HH:mm:ss").substring(0, 5) +
                                      this.state.wednesday.substring(5, 11),
                                  });
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <b>-</b>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                className="datePicker"
                                minutesStep="30"
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.wednesday.substring(6, 11)
                                )}
                                skipDisabled={true}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    wednesday:
                                      this.state.wednesday.substring(0, 6) +
                                      value.format("HH:mm:ss").substring(0, 5),
                                  });
                                }}
                                views={["hours", "minutes"]}
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          <img
                            className="removeIcon"
                            onClick={() => this.handleClosed(2)}
                            src={icon3}
                            alt="subtract"
                          ></img>
                        </div>
                      )}
                      {this.state.closedDays[2] && (
                        <div className="datpicker-content justify-content-between">
                          <label className="closedLabel">Closed</label>
                          <img
                            className="addIcon"
                            onClick={() => this.handleClosed(2)}
                            src={icon4}
                            alt="add"
                          ></img>
                        </div>
                      )}
                    </div>
                    <div className="timeBox">
                      <label className="timeLabel">
                        {t("overview.thursday")}
                      </label>
                      {!this.state.closedDays[3] && (
                        <div className="datpicker-content">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                minutesStep="30"
                                // defaultValue={this.state.monday.substring(0, 5)}
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.thursday.substring(0, 5)
                                )}
                                skipDisabled={true}
                                views={["hours", "minutes"]}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    thursday:
                                      value.format("HH:mm:ss").substring(0, 5) +
                                      this.state.thursday.substring(5, 11),
                                  });
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <b>-</b>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                className="datePicker"
                                minutesStep="30"
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.thursday.substring(6, 11)
                                )}
                                skipDisabled={true}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    thursday:
                                      this.state.thursday.substring(0, 6) +
                                      value.format("HH:mm:ss").substring(0, 5),
                                  });
                                }}
                                views={["hours", "minutes"]}
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          <img
                            className="removeIcon"
                            onClick={() => this.handleClosed(3)}
                            src={icon3}
                            alt="subtract"
                          ></img>
                        </div>
                      )}
                      {this.state.closedDays[3] && (
                        <div className="datpicker-content justify-content-between">
                          <label className="closedLabel">Closed</label>
                          <img
                            className="addIcon"
                            onClick={() => this.handleClosed(3)}
                            src={icon4}
                            alt="add"
                          ></img>
                        </div>
                      )}
                    </div>
                    <div className="timeBox">
                      <label className="timeLabel">
                        {t("overview.friday")}
                      </label>
                      {!this.state.closedDays[4] && (
                        <div className="datpicker-content">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                minutesStep="30"
                                // defaultValue={this.state.monday.substring(0, 5)}
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.friday.substring(0, 5)
                                )}
                                skipDisabled={true}
                                views={["hours", "minutes"]}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    friday:
                                      value.format("HH:mm:ss").substring(0, 5) +
                                      this.state.friday.substring(5, 11),
                                  });
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <b>-</b>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                className="datePicker"
                                minutesStep="30"
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.friday.substring(6, 11)
                                )}
                                skipDisabled={true}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    friday:
                                      this.state.friday.substring(0, 6) +
                                      value.format("HH:mm:ss").substring(0, 5),
                                  });
                                }}
                                views={["hours", "minutes"]}
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          <img
                            className="removeIcon"
                            onClick={() => this.handleClosed(4)}
                            src={icon3}
                            alt="subtract"
                          ></img>
                        </div>
                      )}
                      {this.state.closedDays[4] && (
                        <div className="datpicker-content justify-content-between">
                          <label className="closedLabel">Closed</label>
                          <img
                            className="addIcon"
                            onClick={() => this.handleClosed(4)}
                            src={icon4}
                            alt="add"
                          ></img>
                        </div>
                      )}
                    </div>
                    <div className="timeBox">
                      <label className="timeLabel">
                        {t("overview.saturday")}
                      </label>
                      {!this.state.closedDays[5] && (
                        <div className="datpicker-content">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                minutesStep="30"
                                // defaultValue={this.state.monday.substring(0, 5)}
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.saturday.substring(0, 5)
                                )}
                                skipDisabled={true}
                                views={["hours", "minutes"]}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    saturday:
                                      value.format("HH:mm:ss").substring(0, 5) +
                                      this.state.saturday.substring(5, 11),
                                  });
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <b>-</b>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                className="datePicker"
                                minutesStep="30"
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.saturday.substring(6, 11)
                                )}
                                skipDisabled={true}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    saturday:
                                      this.state.saturday.substring(0, 6) +
                                      value.format("HH:mm:ss").substring(0, 5),
                                  });
                                }}
                                views={["hours", "minutes"]}
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          <img
                            className="removeIcon"
                            onClick={() => this.handleClosed(5)}
                            src={icon3}
                            alt="subtract"
                          ></img>
                        </div>
                      )}
                      {this.state.closedDays[5] && (
                        <div className="datpicker-content justify-content-between">
                          <label className="closedLabel">Closed</label>
                          <img
                            className="addIcon"
                            onClick={() => this.handleClosed(5)}
                            src={icon4}
                            alt="add"
                          ></img>
                        </div>
                      )}
                    </div>
                    <div className="timeBox">
                      <label className="timeLabel">
                        {t("overview.sunday")}
                      </label>
                      {!this.state.closedDays[6] && (
                        <div className="datpicker-content">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                minutesStep="30"
                                // defaultValue={this.state.monday.substring(0, 5)}
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.sunday.substring(0, 5)
                                )}
                                skipDisabled={true}
                                views={["hours", "minutes"]}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    sunday:
                                      value.format("HH:mm:ss").substring(0, 5) +
                                      this.state.sunday.substring(5, 11),
                                  });
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                          <b>-</b>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DesktopTimePicker"]}>
                              <DesktopTimePicker
                                ampm={false}
                                className="datePicker"
                                minutesStep="30"
                                value={dayjs(
                                  "2022-04-17T" +
                                  this.state.sunday.substring(6, 11)
                                )}
                                skipDisabled={true}
                                onChange={(value) => {
                                  this.handleChange();
                                  this.setState({
                                    sunday:
                                      this.state.sunday.substring(0, 6) +
                                      value.format("HH:mm:ss").substring(0, 5),
                                  });
                                }}
                                views={["hours", "minutes"]}
                              />
                            </DemoContainer>
                          </LocalizationProvider>

                          <img
                            className="removeIcon"
                            onClick={() => this.handleClosed(6)}
                            src={icon3}
                            alt="subtract"
                          ></img>
                        </div>
                      )}
                      {this.state.closedDays[6] && (
                        <div className="datpicker-content justify-content-between">
                          <label className="closedLabel">Closed</label>
                          <img
                            className="addIcon"
                            onClick={() => this.handleClosed(6)}
                            src={icon4}
                            alt="add"
                          ></img>
                        </div>
                      )}
                    </div>
                  </form>
                </div>

                <div className="tempTimesDiv">
                  <p className="contentTitle topTitle floatLeft">
                    Temporarily closed
                  </p>
                  <div className="dayPickerDiv">
                    <p className="timeFromTo">
                      {this.state.multiDay ? "From:" : "Date:"}
                    </p>

                    <div className="inputDiv">
                      <DayPickerInput
                        placeholder=""
                        value={this.state.closedFrom}
                        onDayChange={(value) => {
                          this.handleChange();
                          if (value) {
                            let splits = value.toLocaleDateString().split("/");
                            this.setState({
                              closedFrom:
                                splits[0] + "-" + splits[1] + "-" + splits[2],
                            });
                          } else {
                            this.setState({ closedFrom: "" });
                          }
                        }}
                      />
                    </div>
                  </div>
                  {this.state.multiDay && (
                    <div className="dayPickerDiv bottomDayPicker">
                      <p className="timeFromTo">To:</p>
                      <div className="inputDiv">
                        <DayPickerInput
                          placeholder=""
                          value={this.state.closedTo}
                          onDayChange={(value) => {
                            this.handleChange();
                            if (value) {
                              let splits = value
                                .toLocaleDateString()
                                .split("/");
                              this.setState({
                                closedTo:
                                  splits[0] + "-" + splits[1] + "-" + splits[2],
                              });
                            } else {
                              this.setState({ closedTo: "" });
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <label>
                    <span className="eventSub">Multiday:</span>
                    <Toggle
                      className="toggleBtn"
                      checked={this.state.multiDay}
                      icons={false}
                      onChange={() => {
                        this.handleChange();
                        this.setState({
                          multiDay: !this.state.multiDay,
                          closedTo: "",
                        });
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Overview);
