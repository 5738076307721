import React, { Component } from "react";
import { Helmet } from "react-helmet";
import BarImage from "./images/bar.jpg";
import icon1 from "./icons/sortwhite.png";
import icon2 from "./icons/addyellow.png";
import edit from "./icons/edit.png";
import Spinner from "react-bootstrap/Spinner";

import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import placeholder from "./images/barPlaceholderAlt.png";
import { withTranslation } from "react-i18next";
import { Switch, Route, NavLink, BrowserRouter } from "react-router-dom";
import firebase from "firebase/compat/app";

class Events extends Component {
  constructor(props) {
    super(props);

    this.pageChange = this.pageChange.bind(this);

    this.state = {
      info: "",
      renderList: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const data = localStorage.getItem("info");
    const info = JSON.parse(data);
    let renderList = [];
    if (info !== undefined) {
      //sorting events on date then time

      info.events.forEach(async (element) => {
        let elem = element;
        let url = placeholder;
        await firebase
          .storage()
          .ref("eventImages")
          .child(info.id + element.id)
          .getDownloadURL()
          .then((u) => {
            url = u;
          })
          .catch((error) => {
            console.log(error);
          });
        elem.url = url;
        renderList.push(elem);
        this.setState({ renderList, info, loading: false });
      });
    }

    if (info.events.length == 0) {
      this.setState({ loading: false });
    }
  }

  pageChange(element) { }

  trimInfoString(info) {
    let newInfo = info.substr(0, 60);
    if (info.length > 60) {
      newInfo = newInfo + " ...";
    }
    return newInfo;
  }

  renderEvents() {
    let eventRenders = [];
    let pastEventsRenders = [];
    const events = this.state.renderList.sort((a, b) => {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      if (a.hours > b.hours) return 1;
      if (a.hours < b.hours) return -1;
    });
    events.forEach((element) => {

      console.log("element==>" + JSON.stringify(element));
      let futureEvent = false;
      const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      let date = element.date;
      if (date.length == 3) {
        if (date[0] == date[2]) {
          date = days[parseInt(date[0]) - 1];
        } else {
          date =
            days[parseInt(date[0]) - 1] + " - " + days[parseInt(date[2]) - 1];
        }
        futureEvent = true;
      } else {
        if (date.length > 15) {
          const endDate = date.split(" - ")[1];
          const endTime = element.hours.split("-")[1];
          if (new Date(endDate + "T" + endTime) > new Date()) {
            futureEvent = true;
          }
        } else {
          const endTime = element.hours.split("-")[1] || "00:00";
          if (new Date(date + "T" + endTime) > new Date()) {
            futureEvent = true;
          }
        }
      }
      let eventCat = "";
      if (element.type.length) {
        eventCat = " • " + element.type;
      }

      if (futureEvent) {
        eventRenders.push(
          <div
            className="eventLink"
            onClick={() => {
              this.props.history.push("/Event");
              localStorage.setItem("event", JSON.stringify(element));
            }}
          >
            <div className="content contentWide eventBox boxShadow">
              <div className="eventsInfo">
                <p className="eventsTitle">
                  {element.title}
                  <span className="eventCat">{eventCat}</span>
                </p>
                <p className="eventsTime">
                  {date}
                  <span className="hoursMargin">{element.hours}</span>
                </p>
                <p className="eventsMenu">
                  {this.trimInfoString(element.info)}
                </p>
              </div>
              <div className="eventsImage">
                <img className="barImg" src={element.url} alt="BarImg"></img>
              </div>
            </div>
            <img className="iconEdit" src={edit} alt="edit"></img>
          </div>
        );
      } else {
        pastEventsRenders.push(
          <div
            className="eventLink"
            onClick={() => {
              this.props.history.push("/Event");
              localStorage.setItem("event", JSON.stringify(element));
            }}
          >
            <div className="content contentWide eventBox boxShadow  ">
              <div className="eventsInfo">
                <p className="eventsTitle">
                  {element.title}
                  <span className="eventCat">{eventCat}</span>
                </p>
                <p className="eventsTime">
                  {date}
                  <span className="hoursMargin">{element.hours}</span>
                </p>
                <p className="eventsMenu">
                  {this.trimInfoString(element.info)}
                </p>
              </div>
              <div className="eventsImage">
                <img className="barImg" src={element.url} alt="BarImg"></img>
              </div>
            </div>
            <img className="iconEdit" src={edit} alt="edit"></img>
          </div>
        );
      }
    });
    return [eventRenders, pastEventsRenders];
  }

  addEvent() {
    let renderList = this.state.renderList;
    let id = 0;
    renderList.forEach((event) => {
      if (event.id > id) {
        id = event.id;
      }
    });
    let now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let day = now.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let from = now.getHours();
    let to = from + 1;
    id = parseInt(id) + 1;
    renderList = [
      ...renderList,
      {
        date: year + "-" + month + "-" + day,
        drinks: [],
        hours: from + ":00" + "-" + to + ":00",
        id: id,
        info: "",
        type: "",
        title: "",
        url: placeholder,
      },
    ];
    this.setState({ renderList });
  }

  render() {
    const { t } = this.props;
    return (
      <div className="eventsDiv">
        <Helmet>
          <style>{"body { background-color: #F7F7F7 !important; }"}</style>
        </Helmet>
        <div className="eventsTitleBar">
          <p className="tabTitle floatLeft">{t("events.events")}</p>
        </div>
        <div className="iconEventsDiv">
          <button class="icon-button" onClick={() => this.addEvent()}>
            <img
              className="icon"
              src={require("../src/icons/plus.png")}
              alt="icon"
            ></img>{" "}
            Add Events
          </button>
        </div>
        <div className="contentEventsOuter">
          {this.state.renderList.length == 0 && !this.state.loading && (
            <div className="spinnerDivEvent">
              <p className="eventsPrompt">{t("events.pleasePress")}</p>
            </div>
          )}
          {this.state.loading && (
            <div className="spinnerDivEvent">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          {this.renderEvents()[0]}
          {this.renderEvents()[1]}
        </div>
        <div className="iconEventsDivMobile">
          {/* <a className="linkStyle"> */}
          {/*<img className="iconEvents boxShadow" src={icon1} alt="icon"></img>*/}
          {/* </a> */}
          <a className="linkStyle boxShadow" onClick={() => this.addEvent()}>
            <img className="iconEvents" src={icon2} alt="icon"></img>
            Add Events
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Events);
